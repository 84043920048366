import { Outlet, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react'

import { useStore } from 'store'

const PrivateRoutes = observer(() => {
  const { userStore } = useStore()

  return (
    userStore.isAuthenticated ? <Outlet/> : <Navigate to="/auth"/>
  )
})

export default PrivateRoutes
