import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import { ReactComponent as VariantV3 } from 'assets/images/variantMobileV3.svg'
import { ReactComponent as VariantVR1 } from 'assets/images/variantMobileVR1.svg'

import styles from './styles.module.scss'

interface IProps {
  isVRActive: boolean,
  setVariantVR: () => void,
  setVariantSomnium: () => void
}

export const MobileMenu: FC<IProps> = ({ isVRActive, setVariantVR, setVariantSomnium }) => {
  return (
    <div className={cn(styles.mobileMenu)}>
      <div className={styles.buttonsBlock}>
        <button onClick={setVariantVR}
                disabled={isVRActive}
                className={cn({
                  [styles.active]: isVRActive
                })}>
          <VariantVR1 className={styles.variantVR1}/>
        </button>
        <button onClick={setVariantSomnium}
                disabled={!isVRActive}
                className={cn({
                  [styles.active]: !isVRActive
                })}>
          <VariantV3 className={styles.variantV3}/>
        </button>
      </div>
    </div>
  )
}
