import { createContext, useContext } from 'react'
import { configure } from 'mobx'
import remotedev from 'mobx-remotedev'
import user from './user'
import loading from './loading'
import reserveVR from './reserveVR'
import account from './account'
import documentation from './documentation'
import cart from './cart'
import news from './news'

configure({ enforceActions: 'observed' })

@remotedev({ global: true })

class RootStore {
  userStore = user
  loadingStore = loading
  reserveVRStore = reserveVR
  accountStore = account
  documentationStore = documentation
  cart = cart
  newsStore = news
}

const store = new RootStore()

export const StoreContext = createContext<RootStore>(store)

export const useStore = (): RootStore => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('You have forgot to use StoreProvider, shame on you.')
  }
  return store
}

export default store
