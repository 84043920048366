import React, { FC, useCallback } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import { MobileMenu } from './MobileMenu'


import { useStore } from 'store'
import { SiteVariant } from 'utils'
import { useMediaQuery } from 'utils/hooks'

import { ReactComponent as VariantV3 } from 'assets/images/variantV3.svg'
import { ReactComponent as VariantVR1 } from 'assets/images/variantVR1.svg'

import styles from './styles.module.scss'

interface IProps {
}

export const ToggleSiteMenu: FC<IProps> = observer(() => {
  const location = useLocation()

  const width_576 = useMediaQuery('(max-width: 576px)')
  const { userStore } = useStore()
  const isVRActive = userStore.siteVariant === SiteVariant.VR
  const setVariantVR = useCallback(() => {
    userStore.setSiteVariant(SiteVariant.VR)
  }, [])

  const setVariantSomnium = useCallback(() => {
    userStore.setSiteVariant(SiteVariant.SOMNIUM)
  }, [])

  if (width_576 === undefined) return null
  if (!(location.pathname === '/')) return null

  return (
    <>
      {width_576
        ? <MobileMenu isVRActive={isVRActive} setVariantVR={setVariantVR} setVariantSomnium={setVariantSomnium}/>
        : <div className={cn({
          [styles.toggleSiteMenu]: true,
          [styles.toggleSiteMenuVR]: isVRActive,
          [styles.toggleSiteMenuSomnium]: !isVRActive
        })}>
          <div className={styles.buttonsBlock}>
            <button onClick={setVariantVR}
                    disabled={isVRActive}
                    className={cn({
                      [styles.active]: isVRActive
                    })}>
              <VariantVR1 className={styles.variantVR1}/>
            </button>
            <button onClick={setVariantSomnium}
                    disabled={!isVRActive}
                    className={cn({
                      [styles.active]: !isVRActive
                    })}>
              <VariantV3 className={styles.variantV3}/>
            </button>
          </div>
        </div>}
    </>
  )
})
