import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'


class CartStore {
  variants: number[] = []
  accessories: number[] = []

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'cart',
      properties: ['variants', 'accessories'],
      storage: window.localStorage,
    })
  }

  addVariant(id: number) {
    this.variants = [...this.variants, id]
  }

  removeVariant(id: number) {
    const index = this.variants.indexOf(id)
    this.variants = index !== -1 ? [...this.variants.slice(0, index), ...this.variants.slice(index + 1)] : this.variants
  }

  addAccessory(id: number) {
    this.accessories = [...this.accessories, id]
  }

  removeAccessory(id: number) {
    const index = this.accessories.indexOf(id)
    this.accessories = index !== -1 ? [...this.accessories.slice(0, index), ...this.accessories.slice(index + 1)] : this.accessories
  }
}

export default new CartStore()
