import React, { CSSProperties, FC, Fragment, useEffect } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'

import Box from '@mui/material/Box'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Breakpoint, SxProps } from '@mui/system'

import { ButtonMUI } from 'components/ButtonMUI'

import { useStore } from 'store'

import { ReactComponent as CloseIcon } from 'assets/images/close.svg'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onClose?: () => void
  children: React.ReactNode
  disableOutsideClick?: boolean
  scroll?: DialogProps['scroll']
  dialogMode?: boolean
  title?: React.ReactNode
  titleSX?: SxProps
  subTitle?: React.ReactNode
  subTitleSX?: SxProps
  contentSX?: SxProps
  headerSX?: SxProps
  dialogTitle?: React.ReactNode
  dialogTitleSX?: SxProps
  dialogActions?: React.ReactNode
  dialogActionsSX?: SxProps
  dialogContentSX?: SxProps
  dialogContentTextSX?: SxProps
  buttonTitle?: React.ReactNode
  buttonStyleType?:
    | 'blue'
    | 'blue_2'
    | 'blue_4'
    | 'green'
    | 'green_2'
    | 'green_3'
    | 'green_4'
    | 'white'
  onClickButton?: () => void
  disabledButton?: boolean
  buttonSX?: SxProps
  closeButton?: boolean
  maxWidth?: Breakpoint | false
}

export const Modal: FC<IProps> = ({
  open,
  onClose,
  children,
  disableOutsideClick,
  scroll,
  dialogMode,
  title,
  titleSX,
  subTitle,
  subTitleSX,
  contentSX,
  headerSX,
  dialogTitle,
  dialogTitleSX,
  dialogActions,
  dialogContentSX,
  dialogContentTextSX,
  dialogActionsSX,
  buttonTitle,
  buttonStyleType = 'blue_4',
  onClickButton,
  disabledButton,
  buttonSX,
  closeButton,
  maxWidth = 'sm',
}) => {
  const descriptionElementRef = React.useRef<HTMLElement>(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])
  return (
    <Dialog
      open={open}
      scroll={scroll}
      onClose={!disableOutsideClick ? onClose : undefined}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          margin: { xs: '15px', sm: '32px' },
          width: '100%',
          borderRadius: '15px',
          border: '1px solid #FFF',
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), rgba(255, 255, 255, 0.10)',
          boxShadow: '-5px 5px 15px 0px rgba(0, 0, 0, 0.40)',
          backdropFilter: 'blur(12.5px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          ...headerSX,
        },
      }}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {dialogMode ? (
        <Fragment>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              fontFamily: 'Exo, sans-serif',
              fontSize: '1.375rem',
              padding: '20px 20px 12px',
              textAlign: 'center',
              fontWeight: 500,
              color: 'white',
              lineHeight: 'normal',
              ...dialogTitleSX,
            }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent sx={dialogContentSX}>
            <Box
              sx={dialogContentTextSX}
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {children}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px',
              width: '100%',
              ...dialogActionsSX,
            }}
          >
            {dialogActions ? dialogActions : null}
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          {closeButton ? (
            <button className={styles.closeButton} onClick={onClose}>
              <span className={styles.close}>Close</span>
              <CloseIcon className={styles.cross} />
            </button>
          ) : null}
          <Box className={styles.content} sx={contentSX}>
            {title ? (
              <Box sx={titleSX} className={styles.title}>
                {title}
              </Box>
            ) : null}
            {subTitle ? (
              <Box sx={subTitleSX} className={styles.subTitle}>
                {subTitle}
              </Box>
            ) : null}
            {children}
            {buttonTitle ? (
              <ButtonMUI
                styleType={buttonStyleType}
                onClick={onClickButton}
                type="button"
                sx={buttonSX}
                disabled={disabledButton}
              >
                {buttonTitle}
              </ButtonMUI>
            ) : null}
          </Box>
        </Fragment>
      )}
    </Dialog>
  )
}
