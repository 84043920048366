import { useState, useEffect } from 'react'

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState<boolean | undefined>(window.matchMedia(query).matches)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    media.addEventListener('change', () => {
      setMatches(media.matches)
    })

    return () =>
      media.removeEventListener('change', () => {
        setMatches(media.matches)
      })
  }, [matches, query])

  return matches
}
