import React, { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

import Header from 'components/Header'
import Footer from 'components/Footer'
import { ToggleSiteMenu } from 'components/ToggleSiteMenu'

import { useStore } from 'store'
import { SiteVariant } from 'utils'

import styles from './styles.module.scss'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'

interface IProps {
  children: React.ReactNode,
}

export const AppContainer: FC<IProps> = observer(({ children }) => {
  const location = useLocation()
  const {userStore, accountStore } = useStore()
  const [visibleVR, setVisibleVR] = useState(userStore.siteVariant === SiteVariant.VR)
  const [visibleSomnium, setVisibleSomnium] = useState(userStore.siteVariant === SiteVariant.SOMNIUM)
  const [positionStaticVR, setPositionStaticVR] = useState(userStore.siteVariant === SiteVariant.VR)
  const [positionStaticSomnium, setPositionStaticSomnium] = useState(userStore.siteVariant === SiteVariant.SOMNIUM)
  const [transformVR, setTransformVR] = useState(userStore.siteVariant === SiteVariant.VR)
  const [transformSomnium, setTransformSomnium] = useState(userStore.siteVariant === SiteVariant.SOMNIUM)

  useEffect(() => {
    // const root = document.querySelector('#root')

    let timer!: NodeJS.Timeout
    let transformTimer!: NodeJS.Timeout

    if (userStore.siteVariant === SiteVariant.VR) {
      setVisibleVR(true)
      transformTimer = setTimeout(() => {
        setTransformVR(true)
      }, 100)
      timer = setTimeout(() => {
        setTransformSomnium(false)
        setVisibleSomnium(false)
        setPositionStaticSomnium(false)
        setPositionStaticVR(true)
      }, 400)
    } else {
      setVisibleSomnium(true)
      transformTimer = setTimeout(() => {
        setTransformSomnium(true)
      }, 100)
      timer = setTimeout(() => {
        setTransformVR(false)
        setVisibleVR(false)
        setPositionStaticSomnium(true)
        setPositionStaticVR(false)
      }, 400)
    }


    return () => {
      clearTimeout(timer)
      clearTimeout(transformTimer)
    }
  }, [userStore.siteVariant])


  useEffect(() => {
    userStore.isAuthenticated && getAccountInfo()
  }, [])

  const getAccountInfo = async () => {
    try {
      const response = await accountStore.getAccount()
      accountStore.setAccount(response)
    } catch (e) {
      toast.warning('Error while getting account information')
    }
  }


  // Scroll to top
  const wrapperVRRef = useRef<HTMLDivElement | null>(null)
  const wrapperSomnuimRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollToTop()
  }, [location.pathname])

  const scrollToTop = () => {
    wrapperVRRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    wrapperSomnuimRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <Header/>
      {visibleVR
        ? <div ref={wrapperVRRef}
               className={cn({
                 [styles.wrapper]: true,
                 // [styles.visible]: visibleVR,
                 [styles.siteTransform]: transformVR,
                 [styles.siteStatic]: positionStaticVR
               })}>
          <main className={styles.main} key={SiteVariant.VR}>
            {children}
          </main>
          <Footer/>
        </div>
        : null}

      {visibleSomnium
        ? <div ref={wrapperSomnuimRef}
               className={cn({
                 [styles.wrapper]: true,
                 // [styles.visible]: visibleSomnium,
                 [styles.siteTransform]: transformSomnium,
                 [styles.siteStatic]: positionStaticSomnium
               })}>
          <main className={styles.main} key={SiteVariant.SOMNIUM}>
            {children}
          </main>
          <Footer/>
        </div>
        : null}
      <ToggleSiteMenu/>
    </>
  )
})
