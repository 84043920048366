import React, { FC, useEffect } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import { InnerContainer } from 'containers/InnerContainer'
import { Link, Navigate } from 'react-router-dom'
import { PrivacyPolicyModal } from '../PrivacyPolicyModal'

import { staticMultimedia, ROUTES, socialLinks, TStaticMultimedia } from 'utils'

import styles from './styles.module.scss'


interface IProps {
}

const Footer: FC<IProps> = observer(props => {
  const {userStore} = useStore()

  return (
    <footer className={styles.footer}>
      <InnerContainer>
        <div className={styles.box}>
          <div className={styles.logoBox}>
            <Link to={'/'}>
              <img src={staticMultimedia.logo}
                   alt="logo" className={styles.logo}/>
            </Link>
            <div className={styles.text}>
              © 2023 by Somnium Space LTD. Somnium Space and Somnium Space Logo are trademarks of Somnium Space LTD.
            </div>
          </div>

          <div className={styles.linksBox}>
            {/*<div className={styles.linksBoxInner}>*/}
            {/*  <Link to={'/'}>Get Somnium</Link>*/}
            {/*  <Link to={'/'}>Events</Link>*/}
            {/*</div>*/}
            {/*<div className={styles.linksBoxInner}>*/}
            {/*  <Link to={'/'}>Partners</Link>*/}
            {/*  <Link to={'/'}>Support</Link>*/}
            {/*</div>*/}
            {/*<div className={styles.linksBoxInner}>*/}
            {/*  <Link to={'/'}>FAQ</Link>*/}
            {/*  <Link to={'/'}>Contact</Link>*/}
            {/*</div>*/}
            <div className={styles.linksBoxInner}>
              {/*<Link to={'/'}>Terms of Service</Link>*/}
              <PrivacyPolicyModal privacyType={userStore.siteVariant}>
                {onOpen => <a onClick={onOpen}>Privacy Policy</a>}
              </PrivacyPolicyModal>
            </div>
          </div>


          <div className={styles.socialBox}>
            {socialLinks.map(item => (
              <Link to={item.link} target="_blank" rel="noopener noreferrer" key={item.name}>
                <img src={staticMultimedia[item.name as TStaticMultimedia]}
                     alt={item.name}
                     className={styles.socialIcon}/>
              </Link>
            ))}
          </div>

        </div>
      </InnerContainer>
    </footer>
  )
})

export default Footer
