import React, { FC, Fragment, useState } from 'react'
import { SiteVariant } from 'utils'

import { ButtonMUI } from '../ButtonMUI'
import { Modal } from '../Modal'

import styles from './styles.module.scss'

interface IProps {
  children: (onOpen: () => void) => React.ReactNode
  privacyType?: SiteVariant
}

export const PrivacyPolicyModal: FC<IProps> = ({ children, privacyType = SiteVariant.SOMNIUM }) => {
  const [open, setOpen] = useState(false)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      {children(onOpen)}
      {open
        ? <Modal open={open}
                 disableOutsideClick
                 scroll={'paper'}
                 dialogMode
                 maxWidth={'md'}
                 dialogTitle={<div className={styles.title}>Privacy Policy</div>}
                 dialogContentSX={{ padding: '0 20px' }}
                 dialogContentTextSX={{ padding: '10px', background: 'rgba(15, 15, 15, 0.80)' }}
                 dialogActions={(
                   <ButtonMUI styleType={'white'}
                              onClick={onClose}
                              type="button"
                              sx={{ maxWidth: '200px' }}>Close</ButtonMUI>
                 )}
        >
          {privacyType === SiteVariant.VR
            ? <div className={styles.privacy}>
              Privacy Policy for Somnium Space Ltd. VR1 Headset Spot Reservation<br/><br/>
              Last Updated: 21st of September, 2023<br/><br/>
              1. Introduction
              <br/><br/>
              Welcome to Somnium Space Ltd. This Privacy Policy outlines our practices regarding the collection, use,
              and sharing of your personal information. By reserving a spot for the VR1 headset pre-order, you consent
              to the practices described in this policy.
              <br/><br/>
              2. Information We Collect
              <br/><br/>
              When you reserve a spot for the VR1 headset pre-order, we collect the following information:
              <br/><br/>
              <ul>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Choices and text note made during the reservation process</li>
              </ul>
              <br/>
              3. How We Use Your Information
              <br/><br/>
              We use the information we collect to:
              <br/><br/>
              <ul>
                <li>Notify you when orders for the VR1 headset are open.</li>
                <li>Send you updates and information about the VR1 headset and other related products or services.</li>
                <li>Improve our products, services, and customer experience.</li>
              </ul>
              <br/>
              4. How We Share Your Information
              <br/><br/>
              We do not sell or rent your personal information to third parties. We may share your information with
              third-party service providers to fulfill the purposes outlined above, such as sending emails or SMS
              notifications or fulfilling your future orders.
              <br/><br/>
              5. Your Choices
              <br/><br/>
              You have the right to:
              <br/><br/>
              <ul>
                <li>Opt-out of receiving promotional emails or SMS messages.</li>
                <li>Request access to, correction of, or deletion of your personal information.</li>
              </ul>
              <br/>
              To exercise any of these rights, please contact us at [vr1@somniumspace.com].
              <br/><br/>
              6. Security
              <br/><br/>
              We implement appropriate technical and organizational measures to protect your personal information
              against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.
              <br/><br/>
              7. Changes to This Policy
              <br/><br/>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an
              updated revision date.
              <br/><br/>
              8. Contact Us
              <br/><br/>
              If you have any questions or concerns about this Privacy Policy, please contact us at email: <a
              href="mailto:keymaster@somniumspace.com">keymaster@somniumspace.com</a>
            </div>
            : <div className={styles.privacy}>
              Privacy Policy for Somnium Space Ltd.<br/><br/>
              Last Updated: 27th of November, 2023<br/><br/>
              1. Introduction
              <br/><br/>
              Welcome to Somnium Space Ltd's Privacy Policy. At Somnium Space Ltd, we are committed to protecting your
              privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices
              regarding the collection, use, and protection of your data. By using our website, you consent to the
              practices described in this policy.
              <br/><br/>
              2. Information We Collect
              <br/><br/>
              We may collect various types of information from you, including:
              <br/><br/>
              <ul>
                <li>Personal Information: This may include your name, email address, phone number, and any other
                  information you voluntarily provide to us.
                </li>
                <li>Usage Data: We may collect information about how you use our website, including your IP address,
                  browser type, and operating system.
                </li>
              </ul>
              <br/>
              3. How We Use Your Information
              <br/><br/>
              We use your information for the following purposes:
              <br/><br/>
              <ul>
                <li>To provide you with our services and improve your experience on our website.</li>
                <li>To communicate with you regarding updates, promotions, and other relevant information.</li>
                <li>To analyze website usage and improve our services.</li>
              </ul>
              <br/>
              4. Data Security
              <br/><br/>
              We take data security seriously and employ industry-standard measures to protect your information from
              unauthorized access, disclosure, alteration, or destruction.
              <br/><br/>
              5. Data Sharing
              <br/><br/>
              We do not sell, trade, or rent your personal information to third parties. However, we may share your
              information with trusted service providers and partners who assist us in operating our website and
              providing services to you. We ensure that they also maintain the confidentiality of your data.
              <br/><br/>
              6. Cookies
              <br/><br/>
              We use cookies to enhance your experience on our website. You can manage cookie preferences in your
              browser settings.
              <br/><br/>
              7. Your Rights
              <br/><br/>
              You have the following rights with respect to your personal data:
              <br/><br/>
              <ul>
                <li>Access: You can request access to the personal information we hold about you.</li>
                <li>Correction: You can request corrections to inaccuracies in your personal information.</li>
                <li>Deletion: You can request the deletion of your personal information.</li>
                <li>Objection: You can object to the processing of your personal information for certain purposes.</li>
              </ul>
              <br/>
              8. Contact Us
              <br/><br/>
              If you have any questions or concerns about our Privacy Policy or how we handle your data, please contact
              us at <a href="mailto:keymaster@somniumspace.com">keymaster@somniumspace.com</a>
              <br/><br/>
              9. Changes to This Privacy Policy
              <br/><br/>
              We may update this Privacy Policy from time to time to reflect changes in our practices or legal
              requirements. We encourage you to review this policy periodically.
            </div>
          }
        </Modal>
        : null
      }
    </Fragment>
  )
}
