import { isArray, isString } from 'lodash'

import { AccessRolesType } from './const'

export const windowScrollToTop = (value: number = 0) => {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
  }
  window.requestAnimationFrame(() => {
    window.scrollTo({
      top: value,
      behavior: 'smooth',
    })
  })
}

export const copyToClipboardOnAndroid = (textToCopy: string) => {
  let textArea = document.createElement('textarea')
  textArea.value = textToCopy
  textArea.style.position = 'fixed'
  textArea.style.left = '-999999px'
  textArea.style.top = '-999999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  return new Promise((res, rej) => {
    //@ts-ignore
    document.execCommand('copy') ? res() : rej()
    textArea.remove()
  })
}

export const accountHasThisRole = (
  userRoles: string | string[],
  accessRoles: AccessRolesType | AccessRolesType[],
  type: 'all' | 'oneOf'
) => {
  const userRolesArr = isString(userRoles) ? [userRoles] : userRoles
  const accessRolesArr = isString(accessRoles) ? [accessRoles] : accessRoles

  if (type === 'all') {
    if (isArray(userRolesArr)) {
      return accessRolesArr.every(role => userRolesArr.includes(role))
    }
  }

  if (type === 'oneOf') {
    if (isArray(userRolesArr)) {
      return accessRolesArr.some(role => userRolesArr.includes(role))
    }
  }

  return false
}

export const formatDate = (
  dateString: string,
  formatType?: 'default' | 'day_month_time' | 'dd_mm_yy'
) => {
  const date = new Date(dateString)

  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const year = String(date.getFullYear())
  const yearShort = String(date.getFullYear()).slice(2)

  let hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const period = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12

  const time = `${hours}:${minutes} ${period}`

  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const dayName = dayNames[date.getDay()]

  if (formatType === 'day_month_time') {
    return `${dayName} ${month}/${day} - ${time}`
  } else if (formatType === 'dd_mm_yy') {
    return `${day}/${month}/${yearShort}`
  }

  return `${month}/${day}/${year} - ${time}`
}

export const transliterateText = (text: string | undefined): string => {
  return (text || '')
    .toLowerCase()
    .replace(/[ .,/()*&^%$#@!"'|\\`~]+/g, '-')
    .replace(/^-+|-+$/g, '')
}
