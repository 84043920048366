import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import { toast, ToastContainer } from 'react-toastify'

import styles from './styles.module.scss'
import './styles.scss'

interface IProps {
}

export const Toast: FC<IProps> = props => {
  // const onClickS = () => {
  //   toast.success('Sign Up successful!')
  // }
  //
  // const onClickE = () => {
  //   toast.error('Sign Up failed. Please try again.')
  // }
  //
  // const onClickW = () => {
  //   toast.warning('How to create gradient borders in CSS. ... The \'1\' after the linear-gradient declaration is the border-image-slice value.')
  // }
  //
  // const onClickI = () => {
  //   toast.info('How to create gradient borders in CSS. ... The \'1\' after the linear-gradient declaration is the border-image-slice value.')
  // }

  return (
    <>
      {/*<div style={{ position: 'fixed', top: '60px', zIndex: '999' }}>*/}
      {/*  <button onClick={onClickS}>Success</button>*/}
      {/*  <button onClick={onClickE}>Error</button>*/}
      {/*  <button onClick={onClickW}>Warning</button>*/}
      {/*  <button onClick={onClickI}>Info</button>*/}
      {/*</div>*/}
      <ToastContainer
        hideProgressBar
        pauseOnHover
        autoClose={5000}
        className={styles.container}
        toastClassName={styles.toast}
        bodyClassName={styles.body}
        theme="dark"
      />
    </>
  )
}

// Ask in stackoverflow
// How can I change the styles of the react-toastify popup message?

// export enum NOTIFICATIONS_TYPES {
//   SUCCESS = 'success',
//   ERROR = 'error',
// }
//
// const NotificationStringContent: React.FunctionComponent<{
//   content: string;
// }> = ({ content }) => (
//   <Typography component="p" variant="text-200">
//     {content}
//   </Typography>
// );
//
// export const showNotification = (
//   type: NOTIFICATIONS_TYPES,
//   content: string | React.ReactElement,
//   options: ToastOptions = {}
// ) => {
//   const toastContent = typeof content === 'string' ? (
//     <NotificationStringContent content={content} />
//   ) : (
//     content
//   );
//   toast(toastContent, {
//     className: classnames(styles.toast, {
//       [styles.toastSuccess]: type === NOTIFICATIONS_TYPES.SUCCESS,
//       [styles.toastError]: type === NOTIFICATIONS_TYPES.ERROR,
//     }),
//     ...options,
//   });
// };
//
// const NotificationContainer: React.FunctionComponent<{}> = () => (
//   <ToastContainer
//     position="bottom-left"
//     hideProgressBar
//     closeButton={<NotificationCloseButton />}
//     closeOnClick={false}
//     pauseOnHover
//   />
// );
//
// export default NotificationContainer;
