import React, { FC, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { ModifierPhases } from '@popperjs/core'

import Button from '@mui/material/Button'
import Grow from '@mui/material/Grow'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'

import { IMainMenuItem, ISubMenuItem } from 'models'
import { ROUTES } from 'utils'

import ArrowUp from 'assets/images/arrow_up.svg'
import ArrowDown from 'assets/images/arrow_down.svg'
import MenuItem from '@mui/material/MenuItem'

interface IProps {
  item: IMainMenuItem
}

export const CustomMenuItem: FC<IProps> = ({ item }) => {
  const navigate = useNavigate()
  const withSubMenu = item.subMenuList && item.subMenuList.length > 0
  const { subMenuList } = item

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleClickMenuItem = () => {
    if (withSubMenu) {
      setOpen((prevOpen) => !prevOpen)
    } else if (item.redirectPath) {
      if (item.redirectPathNewWindow) {
        window.open(item.redirectPathNewWindow, '_blank', 'noopener,noreferrer')
      } else {
        navigate(item.redirectPath)
      }
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleClickSubMenuItem = (event: Event | React.SyntheticEvent, item: ISubMenuItem) => {
    handleClose(event)
    item.redirectPath && navigate(item.redirectPath)
  }

  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const modifiersPopper = useMemo(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite' as ModifierPhases,
        requires: ['computeStyles'],
        fn({ state }: any) {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`
        },
        effect({ state }: any) {
          state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`
        }
      }
    ],
    []
  )

  return (
    <React.Fragment>
      <Button
        endIcon={withSubMenu
          ? open ? <img src={ArrowUp} alt="arrow-up"/> : <img src={ArrowDown} alt="arrow-down"/>
          : null
        }
        ref={anchorRef}
        id={item.menuItemId}
        aria-controls={open ? item.subMenuListId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenuItem}
        sx={{
          height: '60px',
          padding: '0 10px 5px',
          fontFamily: 'Exo, sans-serif',
          fontSize: '0.875rem',
          fontWeight: '500',
          lineHeight: '100%',
          textTransform: 'capitalize',
          color: open ? '#91DBFF' : '#ABABAB',
          borderTop: open ? '5px solid #91DBFF' : '5px solid rgba(0, 0, 0, 0)',
          borderRadius: 0,
          pointerEvents: item.disabled ? 'none' : 'auto',
          opacity: item.disabled ? 0.6 : 1,

          '&:hover': {
            color: open ? '#91DBFF' : '#FFFFFF',
            borderTop: '5px solid #91DBFF',
            borderRadius: 0
          },

          '&:active': {
            color: '#91DBFF',
            borderTop: '5px solid #91DBFF'
          }
        }}
      >
        {item.text}
      </Button>
      {subMenuList
        ? <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom"
          transition
          disablePortal
          modifiers={modifiersPopper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'top' : 'bottom'
              }}
            >
              <Paper
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  borderRadius: 0,
                  boxShadow: 'none',
                  marginTop: '0'
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id={item.subMenuListId}
                    aria-labelledby={item.menuItemId}
                    onKeyDown={handleListKeyDown}
                    sx={{
                      padding: 0
                    }}
                  >
                    {subMenuList.map(item => (
                      <MenuItem
                        key={item.text}
                        onClick={e => handleClickSubMenuItem(e, item)}
                        sx={{
                          color: '#ABABAB',
                          fontFamily: 'Exo, sans-serif',
                          fontSize: '0.75rem',
                          fontWeight: '500',
                          lineHeight: '100%',
                          textTransform: 'capitalize',
                          marginTop: 0,
                          borderLeft: '5px solid rgba(0, 0, 0, 0)',
                          height: '30px',
                          padding: '6px 5px',

                          '&:hover': {
                            color: '#FFFFFF',
                            borderLeft: '5px solid #91DBFF',
                            height: '30px'
                          },

                          '&:active': {
                            color: '#91DBFF'
                          }
                        }}
                      >
                        {item.text}
                      </MenuItem>
                    ))}

                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        : null}
    </React.Fragment>
  )
}
