import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { SiteVariant } from '../utils'


class UserStore {
  token = ''
  isAuthenticated = false
  trustPeriod = ''
  siteVariant = SiteVariant.VR

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'user',
      properties: ['token', 'isAuthenticated', 'trustPeriod'],
      storage: window.localStorage
    })

    makePersistable(this, {
      name: 'settings',
      properties: ['siteVariant'],
      storage: window.localStorage
    })
  }

  setUserInfo(token: string, isAuthenticated: boolean, trustPeriod: string = '') {
    this.token = token
    this.isAuthenticated = isAuthenticated
    this.trustPeriod = trustPeriod
  }

  setBlankUserInfo() {
    this.token = ''
    this.isAuthenticated = false
    this.trustPeriod = ''
  }

  setSiteVariant(variant: SiteVariant) {
    this.siteVariant = variant
  }
}

export default new UserStore()
