export const ROUTES = {
  AUTH: '/auth',
  AUTH_BETA: '/auth-beta',
  CONFIRM_ALPHA: '/confirm-alpha',
  DOCUMENTATION: '/documentation',
  DOCUMENTATION_OPENED: '/documentation/:chapter/:paragraph',
  DOCUMENTATION_OPENED_STEP: '/documentation/:chapter/:paragraph/:step',
  LICENSE_AGREEMENT: '/license-agreement',
  MAP: '/map',
  MAIN: '/',
  OPENAPI: '/openapi',
  OPENAPI_OPENED: '/openapi/:chapter/:paragraph',
  OPENAPI_OPENED_STEP: '/openapi/:chapter/:paragraph/:step',
  PROFILE: '/profile',
  OVERLAY: '/overlay',
  RESET_PASSWORD_BETA: '/reset-password-beta',
  EVENTS: '/events',
  EVENT_INFO: '/events/:id',
  NEWS: '/news',
  HUB: '/somniumvr1',
}
