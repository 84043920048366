import { useEffect } from 'react'

export const useWheelScroll = (element: HTMLElement | null) => {
  useEffect(() => {
    const onWheelScroll = (event: WheelEvent) => {
      event.preventDefault()

      let [x, y] = [event.deltaX, event.deltaY]
      let magnitude

      const scrollLeft = element?.scrollLeft ?? 0
      const clientWidth = element?.clientWidth ?? 0
      const scrollWidth = element?.scrollWidth ?? 0

      if (scrollLeft + clientWidth === scrollWidth && y < 0) return

      if (x === 0) {
        magnitude = y > 0 ? -8 : 8
      } else {
        magnitude = x
      }

      element?.scrollBy({
        left: magnitude
      })
    }

    element?.addEventListener('wheel', onWheelScroll)

    return () => {
      element?.removeEventListener('wheel', onWheelScroll)
    }
  }, [element])
}
