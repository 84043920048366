import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import cn from 'classnames'
import Button from '@mui/material/Button'
import { SxProps } from '@mui/system'


const PREFIX = 'ButtonMUI'

const classes = {
  button: `${PREFIX}-button`,
  disabled: `${PREFIX}-disabled`,
  blue: `${PREFIX}-blue`,
  blue_2: `${PREFIX}-blue_2`,
  blue_3: `${PREFIX}-blue_3`,
  blue_4: `${PREFIX}-blue_4`,
  blue_5: `${PREFIX}-blue_5`,
  green: `${PREFIX}-green`,
  green_2: `${PREFIX}-green_2`,
  green_3: `${PREFIX}-green_3`,
  green_4: `${PREFIX}-green_4`,
  green_5: `${PREFIX}-green_5`,
  white: `${PREFIX}-white`,
  red: `${PREFIX}-red`,
  red_2: `${PREFIX}-red_2`,
  red_3: `${PREFIX}-red_3`,
  violet: `${PREFIX}-violet`,
  transparent: `${PREFIX}-transparent`,
}

const StyledButton = styled(Button)({
  [`&.${classes.button}`]: {
    height: '50px',
    width: '100%',
    color: '#FFF',
    fontFamily: 'Exo, sans-serif',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'none',
    borderRadius: '25px',
    padding: '6px',

    '&:hover': {
      textShadow: '0 3px 5px rgba(0, 0, 0, 0.25)'
    }
  },
  [`&.${classes.disabled}`]: {
    opacity: 0.5
  },
  [`&.${classes.blue}`]: {
    background: '#1474B8',

    '&:hover': {
      backgroundColor: '#47A7EB'
    },
    '&:active': {
      backgroundColor: '#0A3A5C'
    }
  },
  [`&.${classes.blue_2}`]: {
    background: 'transparent',
    border: '2px solid #1474B8',

    '&:hover': {
      backgroundColor: '#0A3A5C',
      border: '2px solid #1474B8'
    },
    '&:active': {
      backgroundColor: '#47A7EB',
      border: '2px solid #1474B8'
    }
  },
  [`&.${classes.blue_3}`]: {
    background: 'transparent',
    border: '1px solid #FFFFFF',

    '&:hover': {
      backgroundColor: '#0A3A5C',
      border: '2px solid #1474B8'
    },
    '&:active': {
      backgroundColor: '#47A7EB',
      border: '2px solid #1474B8'
    }
  },
  [`&.${classes.blue_4}`]: {
    background: '#19507D',
    border: 'transparent',

    '&:hover': {
      border: '1px solid #0CF',
      backgroundColor: '#19507D'
    },
    '&:active': {
      border: 'none',
      backgroundColor: '#0CF'
    }
  },
  // blue + shadow
  [`&.${classes.blue_5}`]: {
    background: '#1474B8',
    boxShadow: '0px 0px 15px 0px rgba(0, 204, 255, 0.50)',
    '&:hover': {
      backgroundColor: '#47A7EB'
    },
    '&:active': {
      backgroundColor: '#0A3A5C'
    }
  },
  [`&.${classes.green}`]: {
    background: '#00E5BF',

    '&:hover': {
      backgroundColor: '#73E5D2'
    },
    '&:active': {
      backgroundColor: '#007F6A'
    }
  },
  [`&.${classes.green_2}`]: {
    background: 'transparent',
    border: '2px solid #00E5BF',

    '&:hover': {
      border: '2px solid #00E5BF',
      backgroundColor: '#007F6A'
    },
    '&:active': {
      border: '2px solid #00E5BF',
      backgroundColor: '#73E5D2'
    }
  },
  [`&.${classes.green_3}`]: {
    background: 'transparent',
    border: '2px solid #17E547',

    '&:hover': {
      border: '2px solid #17E547',
      backgroundColor: '#007F1E'
    },
    '&:active': {
      border: '2px solid #17E547',
      backgroundColor: '#73E58E'
    }
  },
  [`&.${classes.green_4}`]: {
    background: '#197D6B',
    border: 'transparent',

    '&:hover': {
      border: '1px solid #6FC',
      backgroundColor: '#197D6B'
    },
    '&:active': {
      border: 'transparent',
      backgroundColor: '#5AD9AE'
    }
  },
  [`&.${classes.green_5}`]: {
    background: '#17E547',
    border: '1px solid #FFFFFF',

    '&:hover': {
      border: '1px solid #FFFFFF',
      backgroundColor: '#73E58E'
    },
    '&:active': {
      border: '1px solid #FFFFFF',
      backgroundColor: '#007F1E'
    }
  },
  [`&.${classes.white}`]: {
    background: 'transparent',
    border: '2px solid #FFF',

    '&:hover': {
      backgroundColor: '#808080',
      border: '2px solid #FFF'
    },
    '&:active': {
      backgroundColor: '#C7C9D9',
      border: '2px solid #FFF'
    }
  },
  [`&.${classes.red}`]: {
    background: '#7F0000',
    border: 'transparent',

    '&:hover': {
      backgroundColor: '#F66',
      border: '2px solid #F00'
    },
    '&:active': {
      backgroundColor: '#E50000',
      border: 'transparent'
    }
  },
  [`&.${classes.red_2}`]: {
    background: '#E50000',
    border: '1px solid #FFFFFF',

    '&:hover': {
      backgroundColor: '#FF6666',
      border: '1px solid #FFFFFF'
    },
    '&:active': {
      backgroundColor: '#7F0000',
      border: '1px solid #FF0000'
    }
  },
  [`&.${classes.red_3}`]: {
    background: '#FF6600',
    border: '1px solid #FFFFFF',

    '&:hover': {
      backgroundColor: '#FFA366',
      border: '1px solid #FFFFFF'
    },
    '&:active': {
      backgroundColor: '#993D00',
      border: '1px solid #FF6600'
    }
  },
  [`&.${classes.violet}`]: {
    background: '#6600CC',
    border: '1px solid #FFFFFF',

    '&:hover': {
      backgroundColor: '#AC5DD9',
      border: '1px solid #FFFFFF'
    },
    '&:active': {
      backgroundColor: '#AC5DD9',
      border: '1px solid #FFFFFF'
    }
  },
  [`&.${classes.transparent}`]: {
    background: 'transparent',
    cursor: 'default',

    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: 'transparent'
    }
  }
})


interface IProps {
  children: React.ReactNode
  styleType: 'blue' | 'blue_2' | 'blue_3' | 'blue_4' | 'blue_5' | 'green' | 'green_2' | 'green_3' | 'green_4' | 'green_5' | 'white' | 'red' | 'red_2' | 'red_3',
  sx?: SxProps,
  size?: 'small' | 'medium' | 'large',
  type?: 'submit' | 'reset' | 'button',
  form?: string
  startIcon?: React.ReactNode,
  variant?: 'text' | 'outlined' | 'contained',
  onClick?: () => void,
  disabled?: boolean
}

export const ButtonMUI: FC<IProps> = (
  {
    children,
    sx,
    type,
    form,
    size,
    startIcon,
    styleType,
    variant = 'contained',
    onClick,
    disabled
  }) => {

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick && onClick()
  }

  return (
    <StyledButton
      type={type}
      variant={variant}
      startIcon={startIcon}
      size={size}
      form={form}
      disabled={disabled}
      className={cn({
        [classes.button]: true,
        [classes.disabled]: disabled,
        [classes.blue]: styleType === 'blue',
        [classes.blue_2]: styleType === 'blue_2',
        [classes.blue_3]: styleType === 'blue_3',
        [classes.blue_4]: styleType === 'blue_4',
        [classes.blue_5]: styleType === 'blue_5',
        [classes.green]: styleType === 'green',
        [classes.green_2]: styleType === 'green_2',
        [classes.green_3]: styleType === 'green_3',
        [classes.green_4]: styleType === 'green_4',
        [classes.green_5]: styleType === 'green_5',
        [classes.white]: styleType === 'white',
        [classes.red]: styleType === 'red',
        [classes.red_2]: styleType === 'red_2',
        [classes.red_3]: styleType === 'red_3',
        [classes.transparent]: styleType === 'transparent'
      })}
      sx={sx}
      onClick={handleOnClick}
    >
      {children}
    </StyledButton>
  )
}

// My blue
// [`&.${classes.blue}`]: {
//   background: '#1474B8',
//
//     '&:hover': {
//     backgroundColor: '#47A7EB'
//   },
//   '&:active': {
//     backgroundColor: '#0A3A5C'
//   }
// },


// [`&.${classes.blue}`]: {
//   background: '#1474B8',
//     border: '1px solid #FFFFFF',
//
//     '&:hover': {
//     border: '1px solid #FFFFFF',
//       backgroundColor: '#47A7EB',
//   },
//   '&:active': {
//     border: '1px solid #47A7EB',
//       backgroundColor: '#0A3A5C'
//   }
// },

// '&&': {
//   fontSize: '0.875rem', // for all
// }
// "&.button": { fontSize: '0.875rem' } //if some class

