import React from 'react'
import { observer } from 'mobx-react'

import { useStore } from 'store'

import { ReactComponent as SomniumEmblem } from 'assets/images/somniumEmblem.svg'

import styles from './styles.module.scss'

interface IProps {
  active?: boolean
}

const Loading: React.FC<IProps> = observer(({ active }) => {
  const { loadingStore } = useStore()

  return (
    <>
      {(loadingStore.active || active) && (
        <div className={styles.loading}>
          <div className={styles.loaderBox}>
            <span className={styles.loader}></span>
            <SomniumEmblem/>
          </div>
        </div>
      )}
    </>
  )
})

export default Loading
