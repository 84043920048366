import React from 'react'
import { MobileMenu } from './MobileMenu'
import { CustomMenuItem } from './CustomMenuItem'

import { mainMenuItems, ROUTES } from 'utils'

import styles from './styles.module.scss'

interface IProps {
}

export default function Navigation(props: IProps) {
  return (
    <React.Fragment>
      <MobileMenu/>
      <nav className={styles.navBox}>
        {mainMenuItems
          .filter(item => item.desktop)
          .map((item) => <CustomMenuItem key={item.text} item={item} />)}
      </nav>
    </React.Fragment>
  )
}
