import React, { CSSProperties, FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import Header from 'components/Header'
import Footer from 'components/Footer'

import styles from './styles.module.scss'

interface IProps {
  children: React.ReactNode,
  style?: CSSProperties
}

export const InnerContainer: FC<IProps> = observer((
  {
    children,
    style,
  }) => {

  return (
    <div style={style} className={styles.container}>
      {children}
    </div>
  )
})
