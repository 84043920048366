import { makeAutoObservable } from 'mobx'
import { api } from 'utils'
import { IReserve } from 'models'


class ReserveVRStore {
  constructor() {
    makeAutoObservable(this)
  }

  async reservVRSpot(data: IReserve) {
    const response = await api.post(
      `api/Account/reservVRSpot`,
      data
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async confirmPhoneNumberVRSpot(phoneNumber: string) {
    const response = await api.put(
      `api/Account/confirmPhoneNumberVRSpot`,
      null,
      {
        params: {
          phoneNumber
        }
      }
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async confirmEmailVRSpot(email: string, phoneNumber: string) {
    const response = await api.put(
      `api/Account/confirmEmailVRSpot`,
      null,
      {
        params: {
          phoneNumber,
          email
        }
      }
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async confirmCodeVRSpot(phoneNumber: string, code: string) {
    const response = await api.put(
      `api/Account/confirmCodeVRSpot`,
      null,
      {
        params: {
          phoneNumber,
          code
        }
      }
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async confirmEmailCodeVRSpot(email: string, code: string) {
    const response = await api.put(
      `api/Account/confirmEmailCodeVRSpot`,
      null,
      {
        params: {
          email,
          code
        }
      }
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async createBusinessClientEmail(email: string) {
    const response = await api.post(
      `api/VR1/createBusinessClientEmail`,
      null,
      {
        params: {
          email
        }
      }
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async confirmEmail(email: string, code: string) {
    const response = await api.post(
      `api/VR1/confirmEmail`,
      null,
      {
        params: {
          email,
          code
        }
      }
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async createBusinessClient(data: IReserve) {
    const response = await api.post(
      `api/VR1/createBusinessClient`,
      data
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }
}

export default new ReserveVRStore()
