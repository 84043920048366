import axios from 'axios'
import { makeAutoObservable } from 'mobx'

import { IMediumResponse } from '../utils/const'

class NewsStore {
  mediumArticles: IMediumResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  async getMediumArticles() {
    this.mediumArticles = await axios
      .get(
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@somniumspace'
      )
      .then(e => e?.data)
  }
}

export default new NewsStore()
