import { useState, useEffect, useCallback } from 'react'

export function useTimer(sec: number = 0) {
  const [timer, setTimer] = useState<number>(sec)

  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer > 0 ? currTimer - 1 : 0), [])

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000)
  }, [timer, timeOutCallback])

  const resetTimer = (sec: number = 60) => {
    if (timer <= 0) {
      setTimer(sec)
    }
  }

  return { timer, resetTimer }
}
